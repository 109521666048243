import { template as template_77ca18f965c24162a9d97f6e9b75bf5d } from "@ember/template-compiler";
const FKControlMenuContainer = template_77ca18f965c24162a9d97f6e9b75bf5d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
