import { template as template_b1ab05057bff49d5a7ec8fcf644ec68e } from "@ember/template-compiler";
const FKText = template_b1ab05057bff49d5a7ec8fcf644ec68e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
