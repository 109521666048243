import { template as template_7325471881e24c7c80e907d00f75c2f4 } from "@ember/template-compiler";
const WelcomeHeader = template_7325471881e24c7c80e907d00f75c2f4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
